import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import ShippingInfoModal from "./shippinginfomodal";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import CustomizedSnackbars from "./notification.js";
import CustomizedSnackbarsFailed from "./failednotification.js";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { SmallContainedButton } from "../common/SmallContainedButton";
import { Loader } from "../common/Loader";

import { useSelector, useDispatch } from "react-redux";
import {
  setSearch,
  setTracking,
  setArticles,
  setSubsettings,
  setOrdersNzShopify,
  setSelectedSupplier,
  setDropshipOrders,
  setOrderToDate,
  setOrderFromDate,
  setFullfillment2,
  setPurchasingOrder,
} from "../../actions";

export default function AutofulfillModal({ order, fetchorders }) {
  const order_from_date = useSelector((state) => state.order_from_date);
  const order_to_date = useSelector((state) => state.order_to_date);
  const store = useSelector((state) => state.store);
  const show_orders = useSelector((state) => state.show_orders);
  const selected_supplier = useSelector((state) => state.selected_supplier);
  const [fullfillment, setFullfillment] = useState({
    location_id: 35132997697,
    status: "success",
    tracking_numbers: [""],
    tracking_urls: [""],
    tracking_company: "",
  });
  const [count, setCount] = useState(0);
  const [countship, setCountShip] = useState(0);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => setOpen(false);
  const [notification, setNotification] = React.useState({
    open: false,
    message: "",
  });
  const [notificationfailed, setNotificationFailed] = React.useState({
    open: false,
    message: "",
    order: "",
  });
  const [carrier, setCarrier] = useState("NA");
  const tracking = useSelector((state) => state.tracking);

  const handleChange = (event) => {
    setCarrier(event.target.value);
    let obj = fullfillment;
    if (event.target.value === "NA") {
      obj.tracking_company = "";
    }
    if (event.target.value === "AMSe") {
      obj.tracking_company = "AMSe";
    }
    if (event.target.value === "Aramex") {
      obj.tracking_company = "Aramex";
    }
    if (event.target.value === "UBI") {
      obj.tracking_company = "UBI";
    }
    if (event.target.value === "YANWEN") {
      obj.tracking_company = "YANWEN";
    }
    if (event.target.value === "DHL") {
      obj.tracking_company = "DHL";
    }
    if (event.target.value === "FEDEX") {
      obj.tracking_company = "FEDEX";
    }
    if (event.target.value === "4PX") {
      obj.tracking_company = "4PX";
    }
    if (event.target.value === "dpex") {
      obj.tracking_company = "dpex";
    }
    if (event.target.value === "nzpost") {
      obj.tracking_company = "nzpost";
    }
    if (event.target.value === "Aramex Australia") {
      obj.tracking_company = "Aramex Australia";
    }
    if (event.target.value === "aupost") {
      obj.tracking_company = "aupost";
    }
    if (event.target.value === "PFL") {
      obj.tracking_company = "PFL";
    }
    if (event.target.value === "other") {
      obj.tracking_company = "other";
    }

    setFullfillment(obj);
    setCount(count + 1);
  };
  const handleSave = (event, order) => {
    console.log("ordercheck", JSON.parse(order.line_items));
    if (store === "nzshopify") {
      fetch("https://api.saleprodev.com/updatetracking", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          data: {
            fulfillment: {
              tracking_numbers: fullfillment.tracking_numbers,
              tracking_company: "",
              status: "success",
              line_items: JSON.parse(order.line_items),
              tracking_urls: fullfillment.tracking_urls,
              tracking_company: fullfillment.tracking_company,
            },
            orderid: order.id,
            order: order,
          },
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            setNotification({ open: true, message: "success" });
          }
        });
    }

    if (store === "aushopify") {
      fetch("https://api.saleprodev.com/updatetrackingau", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          data: {
            fulfillment: {
              tracking_numbers: fullfillment.tracking_numbers,
              tracking_company: "",
              status: "success",
              line_items: JSON.parse(order.line_items),
              tracking_urls: fullfillment.tracking_urls,
              tracking_company: fullfillment.tracking_company,
            },
            orderid: order.id,
            order: order,
          },
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            setNotification({ open: true, message: "success" });
          }
        });
    }
  };

  const handleCheckTracking = (event, order) => {
    if (order.supplier === "aliexpress") {
      dispatch(setTracking([order.orderid, true]));
      fetch("https://api.saleprodev.com/getaliexpresstracking", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ order: order }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("this is res of aliexpress tracking-->>>",response);
          dispatch(setTracking([order.orderid, false]));

          let obj;
          if (response.carrier) {
            let tracking_url = "";
            let tracking_company = "";
            // let tracking_url =
            //   "https://www.aftership.com/track/" +
            //   response.tracking_no;
            // let tracking_company = "UBI";

            if (store === "nzshopify") {
              // if (response.carrier === "UBI") {
              //   tracking_url =
              //     "https://www.aftership.com/track/" + response.tracking_no;
              //   tracking_company = "UBI";
              // }
              // if (response.carrier === "YANWEN_AM") {
              //   tracking_url =
              //     "https://track.yw56.com.cn/en/querydel?nums=" +
              //     response.tracking_no;
              //   tracking_company = "YANWENEXPRESS";
              // }
              // if (response.carrier === "DHL") {
              //   tracking_url =
              //     "https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=" +
              //     response.tracking_no;
              //   tracking_company = "DHL";
              // }
              // if (response.carrier === "FEDEX") {
              //   tracking_url =
              //     "https://www.aftership.com/track/" + response.tracking_no;
              //   tracking_company = "FEDEX";
              // }
              // if (
              //   response.carrier === "CAINIAO_STANDARD" ||
              //   response.carrier === "OTHER" ||
              //   response.carrier === "OTHER_NZ" ||
              //   !response.carrier
              // ) {
              //   if (response.tracking_no.startsWith("007")) {
              //     tracking_url =
              //       "https://www.aftership.com/track/ubi-logistics/" +
              //       response.tracking_no;
              //     tracking_company = "UBI";
              //   }
              //   if (response.tracking_no.startsWith("91")) {
              //     tracking_url =
              //       "https://track.4px.com/?#/result/0/" + response.tracking_no;
              //     tracking_company = "4PX";
              //   }
              //   if (response.tracking_no.startsWith("LP")) {
              //     tracking_url =
              //       "https://track.4px.com/?#/result/0/" + response.tracking_no;
              //     tracking_company = "4PX";
              //   }
              //   if (
              //     response.tracking_no.startsWith("UG") ||
              //     response.tracking_no.startsWith("VP") ||
              //     response.tracking_no.startsWith("PY")
              //   ) {
              //     tracking_url =
              //       "https://track.yw56.com.cn/en/querydel?nums=" +
              //       response.response.tracking_no;
              //     tracking_company = "YANWEN";
              //   }
              // }
              // if (response.carrier === "CAINIAO_PREMIUM") {
              //   tracking_url =
              //     "https://www.aftership.com/track/" + response.tracking_no;
              //   tracking_company = "other";
              // }
              // if (response.carrier === "EMS_ZX_ZX_US") {
              //   tracking_url =
              //     "https://www.aftership.com/track/" + response.tracking_no;
              //   tracking_company = "other";
              // }
              // if (response.carrier === "TOLL") {
              //   tracking_url =
              //     "https://dpex.com/track-and-trace/" + response.tracking_no;
              //   tracking_company = "dpex";
            if(response.carrier === "UBI"){
              tracking_url = "https://www.aftership.com/track/ubi-logistics/" + response.tracking_no
              tracking_company = "UBI"
              }
              if(response.carrier === "YANWEN_AM"){
              tracking_url = "https://track.yw56.com.cn/en/querydel?nums=" + response.tracking_no
              tracking_company = "YANWENEXPRESS"
              }
              if(response.carrier === "DHL"){
              tracking_url = "https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=" + response.tracking_no
              tracking_company = "DHL"
              }
              if(response.carrier === "FEDEX"){
              tracking_url = "https://www.trackingmore.com/all/en/" + response.tracking_no
              tracking_company = "FEDEX"
              }
              if(response.carrier === "CAINIAO_STANDARD" || response.carrier === "OTHER" || response.carrier === "OTHER_NZ" || response.carrier === 'CAINIAO_STANDARD_SG' || response.carrier === 'Other' || response.carrier === 'CAINIAO_FULFILLMENT_STD' || response.carrier === 'CAINIAO_FULFILLMENT_SECO_G' || response.carrier === 'CAINIAO_FULFILLMENT_STD_LARGE' || response.carrier === 'CAINIAO_FULFILLMENT_OVER_WH' || response.carrier === 'CAINIAO_FULFILLMENT_STD_SG'){
                if(response.tracking_no.startsWith("LP007")){
                  tracking_url = "https://global.cainiao.com/detail.htm?mailNoList=" + response.tracking_no
                  tracking_company = "CAINIAO"
                }
                if(response.tracking_no.startsWith("007")){
                  tracking_url = "https://www.aftership.com/track/ubi-logistics/" + response.tracking_no
                  tracking_company = "UBI"
                }
                if(response.tracking_no.startsWith("91")) {
                  tracking_url = "https://www.nzpost.co.nz/tools/tracking?trackid=" + response.tracking_no
                  tracking_company = "NZPOST"
                }
                if(response.tracking_no.startsWith("LP")){
                  tracking_url = "https://parcelsapp.com/en/tracking/" + response.tracking_no
                  tracking_company = "CAINIAO"
                }
                if(response.tracking_no.startsWith("UG") || response.tracking_no.startsWith("VP") || response.tracking_no.startsWith("PY") || response.tracking_no.startsWith("PR") || response.tracking_no.startsWith("UJ")) {
                  tracking_url = "https://track.yw56.com.cn/en/querydel?nums=" + response.tracking_no
                  tracking_company = "YANWENEXPRESS"
                }
                if(response.tracking_no.startsWith("CNG")) {
                  tracking_url = "https://global.cainiao.com/detail.htm?mailNoList=" + response.tracking_no
                  tracking_company = "CAINIAO"
                }
              }
              if(response.carrier === "CAINIAO_PREMIUM"){
                tracking_url = "https://global.cainiao.com/detail.htm?mailNoList=" + response.tracking_no
                tracking_company = "CAINIAO"
                }
              if(response.carrier === "EMS_ZX_ZX_US"){
                tracking_url = "https://www.trackingmore.com/all/en/" + response.tracking_no
                tracking_company = "other"
                }
              if(response.carrier === "TOLL"){
                tracking_url = "https://dpex.com/track-and-trace/" + response.tracking_no
                tracking_company = "dpex"
                }

    if(tracking_url === "" && tracking_company === ""){
      console.log("response.carrier not identified", response.carrier, response.tracking_no, order.orderid)
      tracking_url = 'https://www.aftership.com/track/' + response.tracking_no
      tracking_company = 'UBI'

    }


              obj = {
                location_id: 35132997697,
                status: "success",
                tracking_numbers: [response.tracking_no],
                tracking_urls: [tracking_url],
                tracking_company: tracking_company,
              };
            }

            // console.log("this is object  NZ--->>>",obj)

            // return

            if (store === "aushopify") {
              // if (response.carrier === "UBI") {
              //   tracking_url =
              //     "https://www.aftership.com/track/" + response.tracking_no;
              //   tracking_company = "UBI";
              // }
              // if (response.carrier === "CNE") {
              //   tracking_url =
              //     "https://www.aftership.com/track/" + response.tracking_no;
              //   tracking_company = "UBI";
              // }
              // if (response.carrier === "YANWEN_AM") {
              //   tracking_url =
              //     "https://track.yw56.com.cn/en/querydel?nums=" +
              //     response.tracking_no;
              //   tracking_company = "YANWEN";
              // }
              // if (response.carrier === "DHL") {
              //   tracking_url =
              //     "https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=" +
              //     response.tracking_no;
              //   tracking_company = "DHL";
              // }
              // if (response.carrier === "FEDEX") {
              //   tracking_url =
              //     "https://www.aftership.com/track/" + response.tracking_no;
              //   tracking_company = "FEDEX";
              // }
              // if (
              //   response.carrier === "CAINIAO_STANDARD" ||
              //   response.carrier === "OTHER" ||
              //   response.carrier === "OTHER_US" ||
              //   response.carrier === "OTHER_AU" ||
              //   !response.carrier
              // ) {
              //   if (response.tracking_no.startsWith("33")) {
              //     tracking_url =
              //       "https://www.aftership.com/track/" +
              //       response.tracking_no;
              //     tracking_company = "UBI";
              //   }
              //   if (response.tracking_no.startsWith("AUS")) {
              //     tracking_url =
              //       "https://www.aftership.com/track/ubi-logistics/" +
              //       response.tracking_no;
              //     tracking_company = "UBI";
              //   }
              //   if (response.tracking_no.startsWith("NEX")) {
              //     tracking_url =
              //       "https://www.aftership.com/track/" +
              //       response.tracking_no;
              //     tracking_company = "PFL";
              //   }
              //   if (response.tracking_no.startsWith("LB")) {
              //     tracking_url =
              //       "https://track.4px.com/?#/result/0/" + response.tracking_no;
              //     tracking_company = "4PX";
              //   }
              //   if (
              //     response.tracking_no.startsWith("BN") ||
              //     response.tracking_no.startsWith("MP")
              //   ) {
              //     tracking_url =
              //       "https://www.aramex.com.au/tools/track/" +
              //       response.tracking_no;
              //     tracking_company = "aupost";
              //   }
              //   if (
              //     response.tracking_no.startsWith("UG") ||
              //     response.tracking_no.startsWith("VP") ||
              //     response.tracking_no.startsWith("PY")
              //   ) {
              //     tracking_url =
              //       "https://track.yw56.com.cn/en/querydel?nums=" +
              //       response.tracking_no;
              //     tracking_company = "YANWENEXPRESS";
              //   }
              // }
              // if (response.carrier === "CAINIAO_PREMIUM") {
              //   tracking_url =
              //     "https://www.aftership.com/track/" + response.tracking_no;
              //   tracking_company = "other";
              // }
              // if (response.carrier === "EMS_ZX_ZX_US") {
              //   tracking_url =
              //     "https://www.aftership.com/track/" + response.tracking_no;
              //   tracking_company = "other";
              // }
              // if (response.carrier === "TOLL") {
              //   tracking_url =
              //     "https://dpex.com/track-and-trace/" + response.tracking_no;
              //   tracking_company = "dpex";
              // }

              if(response.carrier === "CNE"){
                tracking_url = "https://www.aftership.com/track/ubi-logistics/" + response.tracking_no
                tracking_company = "UBI"
                }
                if(response.carrier === "UBI"){
                tracking_url = "https://www.aftership.com/track/ubi-logistics/" + response.tracking_no
                tracking_company = "UBI"
                }
                if(response.carrier === "YANWEN_AM"){
                tracking_url = "https://track.yw56.com.cn/en/querydel?nums=" + response.tracking_no
                tracking_company = "YANWENEXPRESS"
                }
                if(response.carrier === "AE_CN_SUPER_ECONOMY_G"){
                tracking_url = "https://track.yw56.com.cn/en/querydel?nums=" + response.tracking_no
                tracking_company = "YANWENEXPRESS"
                }
                if(response.carrier === "DHL"){
                tracking_url = "https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=" + response.tracking_no
                tracking_company = "DHL"
                }
                if(response.carrier === "FEDEX"){
                tracking_url = "https://www.trackingmore.com/all/en/" + response.tracking_no
                tracking_company = "FEDEX"
                }
                if(response.carrier === "CAINIAO_STANDARD" || response.carrier === 'CAINIAO_STANDARD_SG' || response.carrier === "OTHER" || response.carrier === "OTHER_US" || response.carrier === "OTHER_AU" || response.carrier === "AUSPOST"|| response.carrier === "Other" || response.carrier === "CAINIAO_FULFILLMENT_STD" || response.carrier === "SGP" || response.carrier === "CAINIAO_FULFILLMENT_SECO_G" || response.carrier === "CAINIAO_FULFILLMENT_STD_LARGE" || response.carrier === "CAINIAO_FULFILLMENT_OVER_WH" || response.carrier === "CAINIAO_FULFILLMENT_STD_SG"){
                  if(response.tracking_no.startsWith("33")){
                    tracking_url = "https://www.aftership.com/track/ubi-logistics/" + response.tracking_no
                    tracking_company = "UBI"
                  }
                  if(response.tracking_no.startsWith("34")){
                    tracking_url = "https://www.aftership.com/track/ubi-logistics/" + response.tracking_no
                    tracking_company = "UBI"
                  }
                  if(response.tracking_no.startsWith("AUS")){
                    tracking_url = "https://www.aftership.com/track/ubi-logistics/" + response.tracking_no
                    tracking_company = "UBI"
                  }
                  if(response.tracking_no.startsWith("LP")){
                    tracking_url = "https://parcelsapp.com/en/tracking/" + response.tracking_no  // https://sypost.net/search?orderNo= changed in dec
                    tracking_company = "CAINIAO"
                  }
                  if(response.tracking_no.startsWith("NEX")){
                      tracking_url = "https://www.trackingmore.com/pflogistics-tracking.html?number=" + response.tracking_no
                      tracking_company = "PFL"
                    }
                  if(response.tracking_no.startsWith("LB")){
                    tracking_url = "https://track.4px.com/?#/result/0/" + response.tracking_no
                    tracking_company = "4PX"
                  }
                  if(response.tracking_no.startsWith("BN") || response.tracking_no.startsWith("MP")) {
                    tracking_url = "https://www.aramex.com.au/tools/track/" + response.tracking_no
                    tracking_company = "aupost"
                  }
                  if(response.tracking_no.startsWith("UG") || response.tracking_no.startsWith("VP") || response.tracking_no.startsWith("PY")) {
                    tracking_url = "https://track.yw56.com.cn/en/querydel?nums=" + response.tracking_no
                    tracking_company = "YANWENEXPRESS"
                  }
                  if(response.tracking_no.startsWith("CNG007")){
                    tracking_url = "https://global.cainiao.com/detail.htm?mailNoList=" + response.tracking_no
                    tracking_company = "CAINIAO"
                  }
                }

                if(tracking_url === "" && tracking_company === ""){
                  console.log("response.carrier not identified", order.supplier_order_no, response.tracking_no,response.carrier)
                  tracking_url = 'https://www.aftership.com/track/' + response.tracking_no
                  tracking_company = 'UBI'
                }
              obj = {
                location_id: 15243640891,
                status: "success",
                tracking_numbers: [response.tracking_no],
                tracking_urls: [tracking_url],
                tracking_company: tracking_company,
              };
            }

            // console.log("this is object  AU--->>>",obj)

            // return

            if (store === "gkshopify") {
              // if (response.carrier === "UBI") {
              //   tracking_url =
              //     "https://www.aftership.com/track/" +
              //     response.tracking_no;
              //   tracking_company = "UBI";
              // }
              // if (response.carrier === "YANWEN_AM") {
              //   tracking_url =
              //     "https://track.yw56.com.cn/en/querydel?nums=" +
              //     response.tracking_no;
              //   tracking_company = "YANWEN";
              // }
              // if (response.carrier === "DHL") {
              //   tracking_url =
              //     "https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=" +
              //     response.tracking_no;
              //   tracking_company = "DHL";
              // }
              // if (response.carrier === "FEDEX") {
              //   tracking_url =
              //     "https://www.aftership.com/track/" + response.tracking_no;
              //   tracking_company = "FEDEX";
              // }
              // if (
              //   response.carrier === "CAINIAO_STANDARD" ||
              //   response.carrier === "OTHER" ||
              //   response.carrier === "OTHER_NZ" ||
              //   !response.carrier
              // ) {
              //   if (response.tracking_no.startsWith("007")) {
              //     tracking_url =
              //       "https://www.aftership.com/track/" +
              //       response.tracking_no;
              //     tracking_company = "UBI";
              //   }
              //   if (response.tracking_no.startsWith("91")) {
              //     tracking_url =
              //       "https://track.4px.com/?#/result/0/" + response.tracking_no;
              //     tracking_company = "4PX";
              //   }
              //   if (response.tracking_no.startsWith("LP")) {
              //     tracking_url =
              //       "https://track.4px.com/?#/result/0/" + response.tracking_no;
              //     tracking_company = "4PX";
              //   }
              //   if (
              //     response.tracking_no.startsWith("UG") ||
              //     response.tracking_no.startsWith("VP") ||
              //     response.tracking_no.startsWith("PY") ||
              //     response.tracking_no.startsWith("PR")
              //   ) {
              //     tracking_url =
              //       "https://track.yw56.com.cn/en/querydel?nums=" +
              //       response.tracking_no;
              //     tracking_company = "YANWEN";
              //   }
              // }
              // if (response.carrier === "CAINIAO_PREMIUM") {
              //   tracking_url =
              //     "https://www.aftership.com/track/" + response.tracking_no;
              //   tracking_company = "other";
              // }
              // if (response.carrier === "EMS_ZX_ZX_US") {
              //   tracking_url =
              //     "https://www.aftership.com/track/" + response.tracking_no;
              //   tracking_company = "other";
              // }
              // if (response.carrier === "TOLL") {
              //   tracking_url =
              //     "https://dpex.com/track-and-trace/" + response.tracking_no;
              //   tracking_company = "dpex";
              // }

              if(response.carrier === "DHL"){
                tracking_url = "https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=" + response.tracking_no
                tracking_company = "DHL"
              }
              if(response.carrier === "UBI"){
                tracking_url = "https://www.aftership.com/track/ubi-logistics/" + response.tracking_no
                tracking_company = "UBI"
              }
              if(response.carrier === "YANWEN_AM"){
                tracking_url = "https://track.yw56.com.cn/en/querydel?nums=" + response.tracking_no
                tracking_company = "YANWENEXPRESS"
              }
              if(response.carrier === "FEDEX"){
                tracking_url = "https://www.trackingmore.com/all/en/" + response.tracking_no
                tracking_company = "FEDEX"
              }
              if(response.carrier === "EMS_ZX_ZX_US"){
                tracking_url = "https://www.trackingmore.com/all/en/" + response.tracking_no
                tracking_company = "other"
                }
              if(response.carrier === "TOLL"){
                tracking_url = "https://dpex.com/track-and-trace/" + response.tracking_no
                tracking_company = "dpex"
              }
              if(response.carrier === "AE_CN_SUPER_ECONOMY_G"){
                tracking_url = "https://track.yw56.com.cn/en/querydel?nums=" + response.tracking_no
                tracking_company = "YANWENEXPRESS"
                }
              if(response.carrier === "CAINIAO_STANDARD" || response.carrier === "OTHER" || response.carrier === "OTHER_NZ" || response.carrier === 'CAINIAO_STANDARD_SG' || response.carrier === 'Other' || response.carrier === 'CAINIAO_FULFILLMENT_STD' || response.carrier === "CAINIAO_FULFILLMENT_SECO_G" || response.carrier === "CAINIAO_FULFILLMENT_STD_LARGE" || response.carrier === "CAINIAO_FULFILLMENT_OVER_WH" || response.carrier === "CAINIAO_FULFILLMENT_STD_SG"){
                if(response.tracking_no.startsWith("007")){
                  tracking_url = "https://www.aftership.com/track/ubi-logistics/" + response.tracking_no
                  tracking_company = "UBI"
                }
                if(response.tracking_no.startsWith("91")) {
                  tracking_url = "https://www.nzpost.co.nz/tools/tracking?trackid=" + response.tracking_no
                  tracking_company = "NZPOST"
                }
                if(response.tracking_no.startsWith("LP")){
                  tracking_url = "https://parcelsapp.com/en/tracking/" + response.tracking_no     // https://sypost.net/search?orderNo= changes done in dec
                  tracking_company = "CAINIAO"
                }
                if(response.tracking_no.startsWith("UG") || response.tracking_no.startsWith("VP") || response.tracking_no.startsWith("PY") || response.tracking_no.startsWith("PR")) {
                  tracking_url = "https://track.yw56.com.cn/en/querydel?nums=" + response.tracking_no
                  tracking_company = "YANWENEXPRESS"
                }
                if(response.tracking_no.startsWith("CNG")){
                  tracking_url = "https://global.cainiao.com/detail.htm?mailNoList=" + response.tracking_no     // added new
                  tracking_company = "CAINIAO"
                }
              }
              if(response.carrier === "CAINIAO_PREMIUM"){
                tracking_url = "https://global.cainiao.com/detail.htm?mailNoList=" + response.tracking_no     // https://www.trackingmore.com/all/en/  , other :-  changes done in dec
                tracking_company = "CAINIAO"
              }
              
              if(tracking_url === "" && tracking_company === ""){
                    console.log("response.carrier not identified", response.carrier, response.tracking_no, order.orderid)
                    tracking_url = 'https://www.aftership.com/track/' + response.tracking_no
                    tracking_company = 'UBI'
              }
              obj = {
                location_id: 35132997697,
                status: "success",
                tracking_numbers: [response.tracking_no],
                tracking_urls: [tracking_url],
                tracking_company: tracking_company,
              };
            }

            // console.log("this is object  GK--->>>",obj)

            // return

            if (store === "usshopify") {
              // if (response.carrier === "UBI") {
              //   tracking_url =
              //     "https://www.aftership.com/track/" + response.tracking_no;
              //   tracking_company = "UBI";
              // }
              // if (response.carrier === "USPS")
              // {
              //   tracking_url = "https://www.aftership.com/track/" + response.tracking_no;
              //   tracking_company = "USPS";
              // }
              // if (response.carrier === "CAINIAO_FULFILLMENT_STD")
              //   {
              //     tracking_url = "https://www.aftership.com/track/" + response.tracking_no;
              //     tracking_company = "CAINIAO_FULFILLMENT_STD";
              //   }
              // if (response.carrier === "CNE") {
              //   tracking_url =
              //     "https://www.aftership.com/track/" + response.tracking_no;
              //   tracking_company = "UBI";
              // }
              // if (response.carrier === "YANWEN_AM") {
              //   tracking_url =
              //     "https://track.yw56.com.cn/en/querydel?nums=" +
              //     response.tracking_no;
              //   tracking_company = "YANWEN";
              // }
              // if (response.carrier === "DHL") {
              //   tracking_url =
              //     "https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=" +
              //     response.tracking_no;
              //   tracking_company = "DHL";
              // }
              // if (response.carrier === "FEDEX") {
              //   tracking_url =
              //     "https://www.aftership.com/track/" + response.tracking_no;
              //   tracking_company = "FEDEX";
              // }
              // if (
              //   response.carrier === "CAINIAO_STANDARD" ||
              //   response.carrier === "OTHER" ||
              //   response.carrier === "OTHER_US" ||
              //   response.carrier === "OTHER_AU" ||
              //   !response.carrier
              // ) {
              //   if (response.tracking_no.startsWith("33")) {
              //     tracking_url =
              //       "https://www.aftership.com/track/" +
              //       response.tracking_no;
              //     tracking_company = "UBI";
              //   }
              //   if (response.tracking_no.startsWith("AUS")) {
              //     tracking_url =
              //       "https://www.aftership.com/track/ubi-logistics/" +
              //       response.tracking_no;
              //     tracking_company = "UBI";
              //   }
              //   if (response.tracking_no.startsWith("NEX")) {
              //     tracking_url =
              //       "https://www.aftership.com/track/" +
              //       response.tracking_no;
              //     tracking_company = "PFL";
              //   }
              //   if (response.tracking_no.startsWith("LB")) {
              //     tracking_url =
              //       "https://track.4px.com/?#/result/0/" + response.tracking_no;
              //     tracking_company = "4PX";
              //   }
              //   if (
              //     response.tracking_no.startsWith("BN") ||
              //     response.tracking_no.startsWith("MP")
              //   ) {
              //     tracking_url =
              //       "https://www.aramex.com.au/tools/track/" +
              //       response.tracking_no;
              //     tracking_company = "aupost";
              //   }
              //   if (
              //     response.tracking_no.startsWith("UG") ||
              //     response.tracking_no.startsWith("VP") ||
              //     response.tracking_no.startsWith("PY")
              //   ) {
              //     tracking_url =
              //       "https://track.yw56.com.cn/en/querydel?nums=" +
              //       response.tracking_no;
              //     tracking_company = "YANWEN";
              //   }
              // }
              // if (response.carrier === "CAINIAO_PREMIUM") {
              //   tracking_url =
              //     "https://www.aftership.com/track/" + response.tracking_no;
              //   tracking_company = "other";
              // }
              // if (response.carrier === "EMS_ZX_ZX_US") {
              //   tracking_url =
              //     "https://www.aftership.com/track/" + response.tracking_no;
              //   tracking_company = "other";
              // }
              // if (response.carrier === "TOLL") {
              //   tracking_url =
              //     "https://dpex.com/track-and-trace/" + response.tracking_no;
              //   tracking_company = "dpex";
              // }
              if(response.carrier === "UBI"){
                tracking_url = "https://www.aftership.com/track/ubi-logistics/" + response.tracking_no
                tracking_company = "UBI"
                }
                if(response.carrier === "YANWEN_AM"){
                tracking_url = "https://track.yw56.com.cn/en/querydel?nums=" + response.tracking_no
                tracking_company = "YANWENEXPRESS"
                }
                if(response.carrier === "DHL"){
                tracking_url = "https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=" + response.tracking_no
                tracking_company = "DHL"
                }
                if(response.carrier === "FEDEX"){
                tracking_url = "https://www.trackingmore.com/all/en/" + response.tracking_no
                tracking_company = "FEDEX"
                }
                if(response.carrier === "CAINIAO_STANDARD" || response.carrier === "OTHER" || response.carrier === "OTHER_NZ" || response.carrier === 'CAINIAO_STANDARD_SG' || response.carrier === 'Other' || response.carrier === 'CAINIAO_FULFILLMENT_STD'|| response.carrier === "CAINIAO_FULFILLMENT_SECO_G" || response.carrier === "CAINIAO_FULFILLMENT_STD_LARGE" || response.carrier === "CAINIAO_FULFILLMENT_OVER_WH" || response.carrier === "CAINIAO_FULFILLMENT_STD_SG"){
                  if(response.tracking_no.startsWith("007")){
                    tracking_url = "https://www.aftership.com/track/ubi-logistics/" + response.tracking_no
                    tracking_company = "UBI"
                  }
                  if(response.tracking_no.startsWith("91")) {
                    tracking_url = "https://www.nzpost.co.nz/tools/tracking?trackid=" + response.tracking_no
                    tracking_company = "NZPOST"
                  }
                  if(response.tracking_no.startsWith("LP")){
                    tracking_url = "https://parcelsapp.com/en/tracking/" + response.tracking_no   //https://sypost.net/search?orderNo= , sypost  , changes made in dec
                    tracking_company = "CAINIAO"
                  }
                  if(response.tracking_no.startsWith("UG") || response.tracking_no.startsWith("VP") || response.tracking_no.startsWith("PY") || response.tracking_no.startsWith("PR")) {
                    tracking_url = "https://track.yw56.com.cn/en/querydel?nums=" + response.tracking_no
                    tracking_company = "YANWENEXPRESS"
                  }
                  if(response.tracking_no.startsWith("CNG")) {
                    tracking_url = "https://global.cainiao.com/detail.htm?mailNoList=" + response.tracking_no   //added in dec
                    tracking_company = "CAINIAO"
                  }
                }
                if(response.carrier === "CAINIAO_PREMIUM"){
                  tracking_url = "https://global.cainiao.com/detail.htm?mailNoList=" + response.tracking_no
                  tracking_company = "CAINIAO"
                  }
                if(response.carrier === "EMS_ZX_ZX_US"){
                  tracking_url = "https://www.trackingmore.com/all/en/" + response.tracking_no
                  tracking_company = "other"
                  }
                if(response.carrier === "TOLL"){
                  tracking_url = "https://dpex.com/track-and-trace/" + response.tracking_no
                  tracking_company = "dpex"
                  }
      if(tracking_url === "" && tracking_company === ""){
        console.log("response.carrier not identified", response.carrier, response.tracking_no, order.orderid)
        tracking_url = 'https://www.aftership.com/track/' + response.tracking_no
        tracking_company = 'UBI'
      }

              obj = {
                location_id: 62701076648,
                status: "success",
                tracking_numbers: [response.tracking_no],
                tracking_urls: [tracking_url],
                tracking_company: tracking_company,
              };
            }                                     // added on 18-Dec-2024 for Autofulfill button


            // console.log("This is object US", obj);
            console.log("obj", obj);
            // return

            setFullfillment(obj);
            if (tracking_url !== "" && tracking_company !== "") {
              handleFullfill(order, obj);
            } else {
              setNotificationFailed({
                open: true,
                message: "Unable to identify Carrier",
                order: order,
              });
            }
          } else {
            if (response.statusCode === 500) {
              setNotificationFailed({
                open: true,
                message: response.errorMessage,
                order: order,
              });
            } else {
              setNotificationFailed({
                open: true,
                message: "Tracking Info not available",
                order: order,
              });
            }
          }
        }).catch((err)=>
        {
          console.log("this is error-->>",err)
        });
    }
    if (order.supplier === "bangood") {
      dispatch(setTracking([order.orderid, true]));
      fetch("https://api.saleprodev.com/getbangoodtracking", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ order: order }),
      })
        .then((response) => response.json())
        .then((response) => {
          let obj;
          console.log("tracking", response);
          dispatch(setTracking([order.orderid, false]));
          let tracking_company = "";
          let tracking_url = "";
          if (store === "nzshopify") {
            tracking_company = "Aramex New Zealand";
            tracking_url = `https://www.aramex.co.nz/tools/track?l=${response.trackingnumber}`;
            obj = {
              location_id: 35132997697,
              status: "success",
              tracking_numbers: [response.trackingnumber],
              tracking_urls: [tracking_url],
              tracking_company: tracking_company,
            };
          }
          if (store === "aushopify") {
            if (response.trackingnumber.startsWith("AUS")) {
              tracking_company = "UBI";
              tracking_url =
                "https://www.aftership.com/track/ubi-logistics/" +
                response.trackingnumber;
            }
            if (
              response.trackingnumber.startsWith("BN") ||
              response.trackingnumber.startsWith("MP")
            ) {
              tracking_company = "Aramex Australia";
              tracking_url =
                "https://www.aramex.com.au/tools/track?l=" +
                response.trackingnumber;
            }
            if (
              response.trackingnumber.startsWith("33") ||
              response.trackingnumber.startsWith("MP")
            ) {
              tracking_company = "aupost";
              tracking_url =
                "https://auspost.com.au/mypost/track/#/details/" +
                response.trackingnumber;
            }

            obj = {
              location_id: 15243640891,
              status: "success",
              tracking_numbers: [response.trackingnumber],
              tracking_urls: [tracking_url],
              tracking_company: tracking_company,
            };
          }

          if (store === "usshopify") {
            obj = {
              location_id: 62701076648,
              status: "success",
              tracking_numbers: [response.trackingnumber],
              tracking_urls: [tracking_url],
              tracking_company: tracking_company,
            };
          }
          console.log("obj", obj);
          if (response.trackingnumber === "") {
            setNotificationFailed({
              open: true,
              message: "Tracking Info not available",
              order: order,
            });
          } else {
            if (tracking_company && tracking_url ) {
              setFullfillment(obj);
              console.log("here");
              handleFullfill(order, obj);
            } else {
              setNotificationFailed({
                open: true,
                message: "Unable to identify carrier",
                order: order,
              });
            }
          }
        });
    }

    if (order.supplier === "fragrancex") {
      dispatch(setTracking([order.orderid, true]));
      fetch(`https://api.saleprodev.com/getfragrancextracking`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ order: order }),
      })
        .then((response) => response.json())
        .then((response) => {
          dispatch(setTracking([order.orderid, false]));
          let obj;
          console.log("tracking", response);
          let tracking_company = response.Carrier ==="DHL"? "DHL Express":"parcelsapp";
          let tracking_url = response.Carrier ==="DHL"?"https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=" + response.TrackingNumber:`https://parcelsapp.com/en/tracking/${response.TrackingNumber}`;
          


          
          if (store === "nzshopify") {
            obj = {
              location_id: 35132997697,
              status: "success",
              tracking_numbers: [response.TrackingNumber],
              tracking_urls: [tracking_url],
              tracking_company: tracking_company,
            };
          }
          if (store === "aushopify") {
            obj = {
              location_id: 15243640891,
              status: "success",
              tracking_numbers: [response.TrackingNumber],
              tracking_urls: [tracking_url],
              tracking_company: tracking_company,
            };
          }

          if (store === "usshopify") {
            obj = {
              location_id: 62701076648,
              status: "success",
              tracking_numbers: [response.TrackingNumber],
              tracking_urls: [tracking_url],
              tracking_company: tracking_company,
            };
          }

          if (store === "gkshopify") {
            obj = {
              location_id: 35132997697,
              status: "success",
              tracking_numbers: [response.TrackingNumber],
              tracking_urls: [tracking_url],
              tracking_company: tracking_company,
            };
          }

          console.log("obj", obj);
          if (response.TrackingNumber) {
            setFullfillment(obj);
            handleFullfill(order, obj);
          } else {
            setNotificationFailed({
              open: true,
              message: "Tracking Info not available",
              order: order,
            });
          }
        });
    }
    
    if (order.supplier === "bigbuy") {
      dispatch(setTracking([order.orderid, true]));
      fetch(`https://api.saleprodev.com/getbigbuytracking`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ order: order }),
      })
        .then((response) => response.json())
        .then((response) => {
          dispatch(setTracking([order.orderid, false]));
          let obj;
          console.log("tracking", response);
          let tracking_company = "PostNL International";
          let tracking_url =`https://www.aftership.com/track/postnl-international/${response.TrackingNumber}`;
          


          
          if (store === "nzshopify") {
            obj = {
              location_id: 35132997697,
              status: "success",
              tracking_numbers: [response.TrackingNumber],
              tracking_urls: [tracking_url],
              tracking_company: tracking_company,
            };
          }
          if (store === "aushopify") {
            obj = {
              location_id: 15243640891,
              status: "success",
              tracking_numbers: [response.TrackingNumber],
              tracking_urls: [tracking_url],
              tracking_company: tracking_company,
            };
          }

          if (store === "usshopify") {
            obj = {
              location_id: 62701076648,
              status: "success",
              tracking_numbers: [response.TrackingNumber],
              tracking_urls: [tracking_url],
              tracking_company: tracking_company,
            };
          }

          if (store === "gkshopify") {
            obj = {
              location_id: 35132997697,
              status: "success",
              tracking_numbers: [response.TrackingNumber],
              tracking_urls: [tracking_url],
              tracking_company: tracking_company,
            };
          }

          console.log("obj", obj);
          if (response.TrackingNumber) {
            setFullfillment(obj);
            handleFullfill(order, obj);
          } else {
            setNotificationFailed({
              open: true,
              message: "Tracking Info not available",
              order: order,
            });
          }
        });
    }

    if (order.supplier === "wefullfill" || order.supplier ==='wefullfillau' ) {
      dispatch(setTracking([order.orderid, true]));
      fetch(`https://api.saleprodev.com/${order.supplier === 'wefullfillau' ? 'getwefullfillautracking':'getwefullfilltracking'}`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ order: order }),
      })
        .then((response) => response.json())
        .then((response) => {
          let obj;
          dispatch(setTracking([order.orderid, false]));
          console.log("tracking", response);
          let tracking_company = "UBI";
          let tracking_url = `https://www.aftership.com/track/${response.trackingnumber}`;
          if (store === "nzshopify") {
            obj = {
              location_id: 35132997697,
              status: "success",
              tracking_numbers: [response.trackingnumber],
              tracking_urls: [tracking_url],
              tracking_company: tracking_company,
            };
          }
          if (store === "aushopify") {
            obj = {
              location_id: 15243640891,
              status: "success",
              tracking_numbers: [response.trackingnumber],
              tracking_urls: [tracking_url],
              tracking_company: tracking_company,
            };
          }

          if (store === "usshopify") {
            obj = {
              location_id: 62701076648,
              status: "success",
              tracking_numbers: [response.trackingnumber],
              tracking_urls: [tracking_url],
              tracking_company: tracking_company,
            };
          }
          console.log("obj", obj);
          if (response.trackingnumber) {
            setFullfillment(obj);
            handleFullfill(order, obj);
          } else {
            setNotificationFailed({
              open: true,
              message: "Tracking Info not available",
              order: order,
            });
          }
        });
    }

    if (order.supplier === "matterhorn") {
      dispatch(setTracking([order.orderid, true]));
      fetch(`https://api.saleprodev.com/getmatterhorntracking`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ order: order }),
      })
        .then((response) => response.json())
        .then((response) => {
          dispatch(setTracking([order.orderid, false]));
          let obj;
          console.log("tracking", response);
          let tracking_company = "Global Express";
          let tracking_url = store === "nzshopify"?`https://www.nzpost.co.nz/tools/tracking?trackid=${response.TrackingNumber}`:`https://auspost.com.au/mypost/track/#/details/${response.TrackingNumber}`;
          


          
          if (store === "nzshopify") {
            obj = {
              location_id: 35132997697,
              status: "success",
              tracking_numbers: [response.TrackingNumber],
              tracking_urls: [tracking_url],
              tracking_company: tracking_company,
            };
          }
          if (store === "aushopify") {
            obj = {
              location_id: 15243640891,
              status: "success",
              tracking_numbers: [response.TrackingNumber],
              tracking_urls: [tracking_url],
              tracking_company: tracking_company,
            };
          }

          // if (store === "usshopify") {
          //   obj = {
          //     location_id: 62701076648,
          //     status: "success",
          //     tracking_numbers: [response.TrackingNumber],
          //     tracking_urls: [tracking_url],
          //     tracking_company: tracking_company,
          //   };
          // }

          // if (store === "gkshopify") {
          //   obj = {
          //     location_id: 35132997697,
          //     status: "success",
          //     tracking_numbers: [response.TrackingNumber],
          //     tracking_urls: [tracking_url],
          //     tracking_company: tracking_company,
          //   };
          // }

          console.log("obj", obj);
          if (response.TrackingNumber) {
            setFullfillment(obj);
            handleFullfill(order, obj);
          } else {
            setNotificationFailed({
              open: true,
              message: "Tracking Info not available",
              order: order,
            });
          }
        });
    }

    if (order.supplier === "sunsky") {
      dispatch(setTracking([order.orderid, true]));
      fetch(`https://api.saleprodev.com/getsunskytracking`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ order: order }),
      })
        .then((response) => response.json())
        .then((response) => {

          dispatch(setTracking([order.orderid, false]));
           

          let obj;
          let TrackingNumber = response?.trackingnumber;
          let tracking_company = response?.tracking_company;
          let tracking_url = response?.tracking_url+response?.trackingnumber;

          // console.log("this is the===>> dddd", tracking_company, tracking_url)
          


          
          if (store === "nzshopify") {
            obj = {
              location_id: 35132997697,
              status: "success",
              tracking_numbers: [TrackingNumber],
              tracking_urls: [tracking_url],
              tracking_company: tracking_company,
            };
          }
          if (store === "aushopify") {
            obj = {
              location_id: 15243640891,
              status: "success",
              tracking_numbers: [TrackingNumber],
              tracking_urls: [tracking_url],
              tracking_company: tracking_company,
            };
          }

          if (store === "usshopify") {
            obj = {
              location_id: 62701076648,
              status: "success",
              tracking_numbers: [TrackingNumber],
              tracking_urls: [tracking_url],
              tracking_company: tracking_company,
            };
          }

          if (store === "gkshopify") {
            obj = {
              location_id: 35132997697,
              status: "success",
              tracking_numbers: [TrackingNumber],
              tracking_urls: [tracking_url],
              tracking_company: tracking_company,
            };
          }

          console.log("obj", obj);
          if (TrackingNumber) {
            setFullfillment(obj);
            handleFullfill(order, obj);
          } else {
            setNotificationFailed({
              open: true,
              message: "Tracking Info not available",
              order: order,
            });
          }
        });
    }
  };

  useEffect(() => {
    if (notification.message === "success") {
      setOpen(false);
      fetchorders();
    }
  }, [notification]);

  const handleFullfill = (order, additional = false) => {
    setCountShip(countship + 1);
    console.log("fullfillment", fullfillment);
    let obj;
    let location_id;
    if (store === "nzshopify") {
      location_id = 35132997697;
    }

    if (store === "aushopify") {
      location_id = 15243640891;
    }

    if (store === "usshopify") {
      location_id = 62701076648;
    }

    if (store === "gkshopify") {
      location_id = 35132997697;
    }

    if (additional) {
      obj = {
        id: order.id,
        orderid: order.orderid,
        fulfillment: {
          fulfillment: {
            location_id: location_id,
            status: "success",
            line_items: JSON.parse(order.line_items),
            tracking_numbers: additional.tracking_numbers,
            tracking_company: additional.tracking_company,
            tracking_urls: additional.tracking_urls,
            notify_customer: true,
          },
        },
      };
    } else {
      obj = {
        id: order.id,
        orderid: order.orderid,
        fulfillment: {
          fulfillment: {
            location_id: location_id,
            status: "success",
            line_items: JSON.parse(order.line_items),
            tracking_numbers: fullfillment.tracking_numbers,
            tracking_company: fullfillment.tracking_company,
            tracking_urls: fullfillment.tracking_urls,
            notify_customer: true,
          },
        },
      };
    }
    // console.log("handle full", obj);
    // return;



    if (store === "nzshopify") {
      fetch("https://api.saleprodev.com/fullfillorder", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ obj: obj, order: order }),
      })
        .then((response) => response.json())
        .then((response) => {
          // console.log("this is the response==>>> ", response)
          if (
            response.status === "success" ||
            response.status === "Line Items already Fulfilled"
          ) {
            setNotification({ open: true, message: response.status });
            fetchorders();
          }
        });
    }

    if (store === "aushopify") {
      fetch("https://api.saleprodev.com/fullfillorderau", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ obj: obj, order: order }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (
            response.status === "success" ||
            response.status === "Line Items already Fulfilled"
          ) {
            setNotification({ open: true, message: response.status });
            fetchorders();
          }
        });
    }

    if (store === "usshopify") {
      fetch("https://api.saleprodev.com/fullfillorderus", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ obj: obj, order: order }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (
            response.status === "success" ||
            response.status === "Line Items already Fulfilled"
          ) {
            setNotification({ open: true, message: response.status });
            fetchorders();
          }
        });
    }

    if (store === "gkshopify") {
      fetch("https://api.saleprodev.com/fullfillordergk", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ obj: obj, order: order }),
      })
        .then((response) => response.json())
        .then((response) => {
          //Can't get a response from back.
          console.log('fullfillordergk', response)
          if (
            response.status === "success" ||
            response.status === "Line Items already Fulfilled"
          ) {
            setNotification({ open: true, message: response.status });
            fetchorders();
          }
        });
    }

    setCount(count + 1);
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ open: false, message: "" });
  };

  const handleCloseNotificationFailed = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotificationFailed({ open: false, message: "", order: "" });
  };
  console.log("tracking", tracking);
  return (
    <>
      {(selected_supplier === "aliexpress" ||
        selected_supplier === "bangood" ||
        selected_supplier === "fragrancex" ||
        selected_supplier === "wefullfill" ||
        selected_supplier === "" ||
        selected_supplier === "dropsite" ||
        selected_supplier === "dropship" ||
        selected_supplier === "vidaxl" ||
        selected_supplier === "bigbuy" ||
        selected_supplier === "sunsky" ||
        selected_supplier === "matterhorn") &&
        order.purchase_status !== "fulfilled" ? (
        tracking[1] && order.orderid === tracking[0] ? (
          <Grid container>
            <Loader size="20px" />
          </Grid>
        ) : (
          <SmallContainedButton
            disabled={
              order.supplier !== "aliexpress" &&
              order.supplier !== "bangood" &&
              order.supplier !== "fragrancex" &&
              order.supplier !== "wefullfill" && 
              order.supplier !== "bigbuy" &&
              order.supplier !== "matterhorn" &&
              selected_supplier !== "sunsky"
            }
            color="secondary"
            onClick={(event) => handleCheckTracking(event, order)}
          >
            Autofulfill
          </SmallContainedButton>
        )
      ) : order.purchase_status === "fulfilled" ? (
        <SmallContainedButton
          color="secondary"
          disabled={true}
          onClick={(event) => setOpen(true)}
        >
          Fulfilled
        </SmallContainedButton>
      ) : (
        <SmallContainedButton
          color="secondary"
          disabled={true}
          onClick={(event) => setOpen(true)}
          width={'200'}
        >
          Add Tracking
        </SmallContainedButton>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ marginTop: "15%", marginLeft: "20%", marginRight: "20%" }}
      >
        <Card>
          <CardHeader title="Mark as Shipped" />
          <CardContent>
            <div>
              <div
                style={{ display: "grid", gridTemplateColumns: "24% 31% 25%" }}
              >
                <p>Carrier</p>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={carrier}
                  label="No"
                  onChange={handleChange}
                  style={{ height: "30px" }}
                >
                  <MenuItem value="NA">Choose Carrier</MenuItem>
                  <MenuItem value="AMSe">AMSe Group</MenuItem>
                  <MenuItem value="Aramex">Aramex NZ</MenuItem>
                  <MenuItem value="Aramex Australia">Aramex AU</MenuItem>
                  <MenuItem value="UBI">UBI</MenuItem>
                  <MenuItem value="YANWEN">YANWEN</MenuItem>
                  <MenuItem value="DHL">DHL</MenuItem>
                  <MenuItem value="FEDEX">FEDEX</MenuItem>
                  <MenuItem value="4PX">4PX</MenuItem>
                  <MenuItem value="dpex">dpex</MenuItem>
                  <MenuItem value="nzpost">NZ POST</MenuItem>
                  <MenuItem value="aupost">AU POST</MenuItem>
                  <MenuItem value="PFL">PFL</MenuItem>
                  <MenuItem value="other">other</MenuItem>
                </Select>
                <div
                  onClick={(event) => {
                    let obj = fullfillment;
                    obj.tracking_numbers = [...obj.tracking_numbers, ""];
                    obj.tracking_urls = [...obj.tracking_urls, ""];
                    setFullfillment(obj);
                    setCount(count + 1);
                  }}
                  style={{ marginLeft: "10%" }}
                >
                  <ControlPointIcon />
                </div>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "10% 10% 35% 30% 25%",
                  gridGap: "2%",
                }}
              >
                <div></div>
                <div></div>
                <div></div>
              </div>

              {fullfillment.tracking_numbers && fullfillment.tracking_numbers.map((item, index) => {
                return (
                  <ShippingInfoModal
                    order={order}
                    setCount={setCount}
                    count={count}
                    index={index}
                    fullfillment={fullfillment}
                    setFullfillment={setFullfillment}
                    carrier={carrier}
                  />
                );
              })}
              {show_orders !== "fulfilled" ? (
                <Button
                  style={{ marginLeft: "80%", marginTop: "1%" }}
                  variant="contained"
                  onClick={(event) => handleFullfill(order)}
                >
                  SAVE
                </Button>
              ) : (
                <Button
                  style={{ marginLeft: "80%", marginTop: "1%" }}
                  variant="contained"
                  onClick={(event) => handleSave(event, order)}
                >
                  EDIT
                </Button>
              )}
            </div>
          </CardContent>
        </Card>
      </Modal>

      <CustomizedSnackbars
        open={notification.open}
        message={notification.message}
        handleClose={handleCloseNotification}
      />
      <CustomizedSnackbarsFailed
        open={notificationfailed.open}
        message={notificationfailed.message}
        handleClose={handleCloseNotificationFailed}
        order={order}
      />
    </>
  );
}
